import React, { useEffect } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { mobileVW, desktopBreakpoint } from '../styles'

// Hooks
import useReviews from '../hooks/useReviews'
import useRenderContentBlocks from '../hooks/useGetContentBlocks'
import useShopCtx from '../hooks/context/useShopCtx'

// Components
import SEO from '../components/shared/SEO'
import ProductReviews from '../components/product/ProductReviews'
import ProductDetails from '../components/product/ProductDetails'
import AcademyDetails from '../components/product/AcademyDetails'
import RelatedProducts from '../components/product/RelatedProducts'

const Product = ({
  location,
  data: {
    contentfulProduct: {
      id,
      title,
      slug,
      seoTitle,
      contentType,
      seoDescription,
      productDescriptionShort,
      shopifyProduct,
      imageThumbnail,
      imageProductPage,
      brand,
      highlightTitle,
      relatedProducts,
      accordions,
      contentBlocksAfterReviews,
      contentBlocksBeforeReviews,
      sizeGuideButtonText,
      sizeGuideContent,
      discountCampaign,
      releaseDateAcademy,
      videoDurationAcademy,
      experts,
      videoAcademy,
    },
  },
}) => {
  const { products } = useShopCtx()
  // const reviews = useReviews(id)

  const productId = products
    ? products.find(product => product.id === id)?.reviewProductId
    : null

  useEffect(() => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: 'productDetailView',
        ecommerce: {
          currencyCode: 'EUR',
          detail: {
            products: [
              {
                slug: location.pathname,
                name: title,
                price: shopifyProduct.variants.edges[0].node.price,
                quantity: 1,
                currency: 'EUR',
                brand: 'CYCLE',
                category: 'Product',
              },
            ],
          },
        },
      })
    }
  }, [])

  return (
    <div>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={imageProductPage[0].file.url}
        slug={slug}
        isProduct
      />
      {contentType[0] === 'academy' ? (
        <AcademyDetails
          id={id}
          // reviews={reviews}
          title={title}
          slug={slug}
          image={imageProductPage[0]}
          releaseDateAcademy={releaseDateAcademy}
          videoDurationAcademy={videoDurationAcademy}
          video={videoAcademy}
          description={productDescriptionShort}
          experts={experts}
          accordions={accordions}
        />
      ) : (
        <ProductDetails
          // reviews={reviews}
          brand={brand}
          images={imageProductPage}
          title={title}
          descriptionShort={productDescriptionShort}
          shopifyProduct={shopifyProduct}
          accordions={accordions}
          sizeGuideButtonText={sizeGuideButtonText}
          sizeGuideContent={sizeGuideContent}
          discountCampaign={discountCampaign}
        />
      )}

      <ContentBlocksWrapper>
        {contentBlocksBeforeReviews &&
          useRenderContentBlocks(contentBlocksBeforeReviews)}

        {/*{slug && slug !== 'inpakservice' && (*/}
        {/*  <ProductReviews*/}
        {/*    reviews={reviews}*/}
        {/*    name={title}*/}
        {/*    slug={slug}*/}
        {/*    shopifyProduct={shopifyProduct}*/}
        {/*    imageUrl={imageThumbnail.file.url}*/}
        {/*    shortDescription={productDescriptionShort}*/}
        {/*    productId={productId}*/}
        {/*  />*/}
        {/*)}*/}

        {contentBlocksAfterReviews &&
          useRenderContentBlocks(contentBlocksAfterReviews)}
      </ContentBlocksWrapper>

      {relatedProducts && <RelatedProducts relatedProducts={relatedProducts} />}
    </div>
  )
}

const ContentBlocksWrapper = styled.div`
  .circleSwiper {
    padding: 0 !important;
  }

  > *:not(.has-background):not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: ${mobileVW(66)} !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;

    &.reviews {
      @media (min-width: ${desktopBreakpoint}) {
        margin-top: 35px !important;
      }
    }

    @media (min-width: ${desktopBreakpoint}) {
      padding-bottom: 74px !important;
    }
  }

  > .has-background + *:not(.has-background) {
    padding-top: ${mobileVW(66)} !important;

    @media (min-width: ${desktopBreakpoint}) {
      padding-top: 74px !important;
    }
  }

  > .reviews:last-child {
    margin-bottom: ${mobileVW(60)};

    @media (min-width: ${desktopBreakpoint}) {
      margin-bottom: 60px;
    }
  }
`

export const PageQuery = graphql`
  query Product($id: String!) {
    contentfulProduct(id: { eq: $id }) {
      id
      title
      seoTitle
      seoDescription
      slug
      productDescriptionShort
      highlightTitle
      brand
      contentType
      shopifyProduct {
        handle
        id
        title
        variants {
          edges {
            node {
              price
            }
          }
        }
      }
      imageThumbnail {
        file {
          url
        }
        fluid(maxWidth: 300) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      imageProductPage {
        file {
          url
        }
        fluid(maxWidth: 700) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      productDescriptionShort
      highlightTitle
      relatedProducts {
        title
        slug
        id
        productDescriptionShort
        highlightTitle
        imageThumbnail {
          fluid(maxWidth: 300) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        imageThumbnailOnHover {
          fluid(maxWidth: 300) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        shopifyProduct {
          handle
          variants {
            edges {
              node {
                price
              }
            }
          }
        }
      }
      contentBlocksBeforeReviews {
        ...contentBlocks
      }
      accordions {
        ...accordion
      }
      sizeGuideButtonText
      sizeGuideContent {
        json
      }
      discountCampaign
      releaseDateAcademy
      videoDurationAcademy
      videoAcademy {
        title
        videoLink
        imageThumbnail {
          fluid(maxWidth: 300) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      experts {
        slug
        title
        jobFunction
        image {
          fluid(maxWidth: 50) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`

export default Product
